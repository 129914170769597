$bg-color: var(--ion-background-color) !important;
$dark-gray: #333;
$medium-gray: rgb(153, 153, 153);

.gradient-primary {
  background: rgb(216, 149, 25);
  background: linear-gradient(0deg, rgba(216, 149, 25, 1) 0%, rgba(253, 187, 45, 1) 100%);
}

.grayscale {
  filter: grayscale(1);
}

.danger {
  color: var(--ion-color-danger);
}

:root {
  --ion-font-family: 'Poppins';
  $font-family-base: 'Poppins';
  $font-family-ios-base: 'Poppins';
  $font-family-md-base: 'Poppins';
  $font-family-wp-base: 'Poppins';

  --ion-color-primary: #f6a91c;
  --ion-color-primary-rgb: 246, 169, 28;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #d89519;
  --ion-color-primary-tint: #f7b233;

  --ion-color-secondary: #000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000000;
  --ion-color-secondary-tint: #1a1a1a;

  --ion-color-tertiary: #fff;
  --ion-color-tertiary-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;

  --ion-color-success: #198754;
  --ion-color-success-rgb: 25, 135, 84;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #1b6d47;
  --ion-color-success-tint: #12b86b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #fbfbfb;
  --ion-color-light-rgb: 245, 245, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8d8;
  --ion-color-light-tint: #f6f6f6;
}

// MEDIA QUERIES
$phone: 576px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;