@import '../../theme/forms.scss';
@import '../../theme/responsive.scss';
@import 'colors.scss';

@font-face {
  font-family: 'nickelodeon';
  font-style: normal;
  font-weight: normal;
  src: url('../xipocio/nickelodeon.ttf') format('truetype');
}

.nickelod {
  font-family: 'nickelodeon';
}

html,
body,
#background-content {
  background-color: $bg-color;
}

main {
  overflow: hidden !important;
}

.overflow-hidden {
  overflow-y: hidden !important;
}

.overflow-y {
  overflow-y: scroll !important;
}

.title-default {
  text-align: center !important;
}

.m-0 {
  margin: 0 !important;
}

.m-auto {
  margin: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-05 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-05 {
  margin: 0.5rem !important;
}

.m1 {
  margin: 1rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  max-width: calc(100% - 2rem);
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  max-width: calc(100% - 4rem);
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
  max-width: calc(100% - 6rem);
}

.mt-0-3 {
  margin-top: .3rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mt-1-5 {
  margin-top: 1.5rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-0-3 {
  margin-bottom: .3rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-1-5 {
  margin-bottom: 1.5rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.px-1 {
  padding: 0 1rem !important;
}

.px-2 {
  padding: 0 2rem !important;
}

.px-3 {
  padding: 0 3rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.py-05 {
  padding: .5rem 0 !important;
}

.py-1 {
  padding: 1rem 0 !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-05 {
  padding-top: .5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-05 {
  padding-bottom: .5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.h-100 {
  height: 100% !important;
}

.flex-grow {
  flex-grow: 1;
}

.flex {
  display: flex;
  flex-direction: row;

  &.column {
    flex-direction: column !important;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-base {
    align-items: baseline;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-evenly {
    justify-content: space-evenly;
  }

  &.justify-around {
    justify-content: space-around;
  }
}

ion-list-header {
  font-size: 22px !important;
  font-weight: 700 !important;
  font-family: var(--ion-font-family, inherit);
  ;
}

.darkgray,
.darkgrey {
  color: $dark-gray !important;
}

input,
textarea {
  caret-color: var(--ion-color-primary) !important;
}

.borderInput {
  border: solid 1px $medium-gray !important;
  border-radius: 8px;
}

.indent {
  text-indent: 4px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

.normal {
  font-weight: 400 !important;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.titlecase {
  text-transform: capitalize;
}

.PUBLISHED:not(.mini-icon) {
  background: var(--ion-color-success);
  color: white;
}

.REVIEW:not(.mini-icon) {
  background: var(--ion-color-primary);
}

.INPROCESS:not(.mini-icon) {
  background: var(--ion-color-medium);
  color: white;
}

.CANCELED:not(.mini-icon) {
  background: var(--ion-color-danger);
  color: white;
}

.DELETED:not(.mini-icon) {
  background: var(--ion-color-danger);
  color: white;
}

ion-toolbar {
  &.PUBLISHED {
    --background: var(--ion-color-success);
  }

  &.REVIEW {
    --background: var(--ion-color-primary);
  }

  &.INPROCESS {
    --background: var(--ion-color-medium);
  }

  &.CANCELED {
    --background: var(--ion-color-danger);
  }

  &.DELETED {
    --background: var(--ion-color-danger);
  }
}

.pointer {
  cursor: pointer !important;
}

.notification-button {
  position: relative !important;
  overflow: visible !important;
  --overflow: visible !important;

  .notification-badge {
    background-color: red;
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 100%;
    height: 19px;
    width: 19px;
    font-size: 12px;
    line-height: 12px;
    padding-right: -7px;
    padding: 4px 0 0 0 !important;
  }
}

ion-label {
  font-weight: 600;
  font-size: smaller;
}

ion-input,
ion-select {
  background-color: white;
  padding-left: 0.6rem !important;
  margin-top: 0.3rem !important;
  margin-bottom: 0.9rem !important;
  border: solid 1px var(--ion-color-light);
  border-radius: .4rem;

  &.has-focus {
    border-color: var(--ion-color-primary);
  }

  &.ng-touched.ng-invalid:not(.has-focus) {
    border-color: var(--ion-color-danger);
  }
}

.has-focus.sc-ion-input-md-h .input-highlight.sc-ion-input-md {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #FFF;
}

::-webkit-scrollbar-thumb {
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: .1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}

.background-danger {
  background-color: var(--ion-color-danger);
  color: black;
  font-weight: bold;
}