@import '../assets/xipocio/colors.scss';
@import '../theme/variables.scss';

ion-label {
  font-weight: 600;
  font-size: smaller;
}

ion-input {
  padding-left: 0.5rem !important;
}

ion-input,
ion-select,
ion-textarea {
  background-color: white;
  padding-left: 0.6rem !important;
  margin-top: 0.3rem !important;
  margin-bottom: 0.9rem !important;
  border: solid 1px var(--ion-color-light);
  border-radius: .4rem;

  &.has-focus {
    border-color: var(--ion-color-primary);
  }

  &.ng-touched.ng-invalid:not(.has-focus) {
    border-color: var(--ion-color-danger);
  }
}

ion-select {
  width: 100%;
  padding-right: 0.6rem !important;
}

.flex {
  .middle {
    width: calc(50% - .3rem);

    &:first-of-type {
      margin-right: .6rem;
    }
  }
}

.error-label {
  color: var(--ion-color-danger);
  margin-left: .6rem;
}

ion-checkbox {
  --size: 20px;
}

.forms-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .form {
    width: 50%;

    &:first-of-type {
      margin-right: 1rem;
    }

    &:last-of-type {
      margin-left: 1rem;
    }
  }
}

// TABLET
@media screen and (max-width: $tablet) {
  .forms-wrapper {
    display: block;

    .form {
      width: 100%;

      &:first-of-type {
        margin-right: 0;
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}

@media screen and (min-width: $laptop) {}