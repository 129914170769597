/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* TABLES */
@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/dark.css";
@import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";

/* XIPOCIO CUSTOM THEME */
@import "./assets/xipocio/theme.scss";
@import "./assets/xipocio/scrollbar.scss";


// .payment-modal {
//   --width: 100vw !important;
//   width: 100vw !important;
//   min-width: 100vw !important;
//   max-width: 100vw !important;
//   --height: 100vh !important;
//   height: 100vh !important;
//   min-height: 100vh !important;
//   max-height: 100vh !important;

// position: absolute;
// top: 0;
// left: 0;
// display: block;
// }


ion-modal.login-modal,
ion-modal.login-modal .modal-wrapper {
  --height: 95dvh !important;
}


ion-modal.adjust-modal {
  --height: 24vh !important;
}