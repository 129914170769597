.responsive-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .responsive-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
}


// TABLET AND UPPER
@media screen and (min-width: $tablet) {
    .responsive-wrapper {
        flex-direction: row;

        .responsive-column {
            max-width: 50%;
        }
    }
  }
